import React, { useState, useEffect, useRef } from 'react'
import { config } from './config'
import { getListPromotionById } from './services/listPromotionById.service'
import { updateStateMoves } from './services/updateStateMoves.service'
import {movePhase, getValidationPromo} from '../../services/movePhase.service'

export const Mover = ({ setmoverShow, data }) => {
	const [openSelect, setOpenSelect] = useState(false)
	const [headValue, setheadValue] = useState(null)
	const [states, setStates] = useState(null)
	const [stateSelect, setstateSelect] = useState(null)
	const [promotionSelect, setpromotionSelect] = useState(null)
	const [idPhase, setIdPhase] = useState(null)
	const [validation, setValidation] = useState(null)
	const [flag, setFlag] = useState(false)	
	const lines = []

	console.log("INFO DATA TARJETA PROMOCION::: ")
	console.log(data)

	useEffect(() => {
		;(async () => {
			try {
				if(!flag){
					setFlag(true)
					const val = await getValidationPromo(data.id_promo, data.estado_promocional)
					console.log("Validacion Promocion !!::: ")
					console.log(val.data)
					setValidation(val.data)
					console.log("Informacion:::::");
					console.log(data);
					console.log("Movimientos permitidos:::::: ")
					console.log(data.movimientos)
					setStates(data.movimientos)
				}
				
			} catch (error) {
				console.log(error)
			}
		})()
	}, [data.id_promo])

	const refCatalogModal = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModal.current.contains(e.target)) {
			setmoverShow(false)(false)
		}
	}
	/*
	useEffect(() => {
		;(async () => {
			const response = await getListPromotionById(data.id)
			if (response) {
				const { data } = response
				setStates(data?.posible_moves)
			}
		})()
	}, [data.id])
	*/
	const handleMoveState = async () => {
		try {
			const response = await updateStateMoves(promotionSelect, stateSelect)
			if (response) window.location.reload()
		} catch (error) {}
	}

	return (
		<div className='EliminarShow Mover' ref={refCatalogModal} style={{height:"200px"}}>
			<form action=''>
				<div className='input_wrapper'>
					<label htmlFor=''>Mover Promoción</label>
					<div className='select_box'>
						<div
							className={`head ${openSelect && 'open_select'}`}
							onClick={(e) => {
								setOpenSelect(!openSelect)
							}}
						>
							<h1 className={headValue && 'active'}>
								{headValue || 'Selecciona un Estado'}
							</h1>
							<svg
								width='15'
								height='15'
								viewBox='0 0 15 15'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect
									width='18'
									height='18'
									transform='translate(0 17) rotate(-90)'
									fill='white'
								/>
								<path
									d='M7.35355 3.64645C7.15829 3.45118 6.84171 3.45118 6.64645 3.64645C6.45118 3.84171 6.45118 4.15829 6.64645 4.35355L7.35355 3.64645ZM11 8L11.3536 8.35355C11.5488 8.15829 11.5488 7.84171 11.3536 7.64645L11 8ZM6.64645 11.6464C6.45118 11.8417 6.45118 12.1583 6.64645 12.3536C6.84171 12.5488 7.15829 12.5488 7.35355 12.3536L6.64645 11.6464ZM6.64645 4.35355L10.6464 8.35355L11.3536 7.64645L7.35355 3.64645L6.64645 4.35355ZM10.6464 7.64645L6.64645 11.6464L7.35355 12.3536L11.3536 8.35355L10.6464 7.64645Z'
									fill='#E8E8E8'
								/>
							</svg>
						</div>
						{openSelect && (
							<ul>
								{
								!states
									? 'Cargando Fases...'
									: states.map((EachRow) => {
												if (EachRow.estado_fase === "Descartado" || EachRow.estado_fase === "En Ejecución") {
													return null; // Omitir este elemento en la iteración
												}
											
												lines.push(EachRow.estado_fase)
												return (
													<li key={EachRow.id}
														onClick={(e) => {
															setOpenSelect(false)
															setheadValue(EachRow.estado_fase)
															setIdPhase(EachRow.id)
															// setstateSelect(EachRow.id)
															// setpromotionSelect(data.id)
														}}
													>
														{EachRow.estado_fase}
													</li>
												)
											
									  })
								}
							</ul>
						)}
					</div>
				</div>
				<textarea style={{display: 'none'}}
					placeholder='Deja un comentario.. (opcional)'
					cols='30'
					rows='10'
					disabled
				></textarea>
				<div id="relleno" style={{height: "50px"}}></div>
				<div className='banner_warn'>
					<svg
						width='23'
						height='24'
						viewBox='0 0 23 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<mask
							id='mask0_2319_430587'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='23'
							height='24'
						>
							<rect y='0.5' width='23' height='23' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_2319_430587)'>
							<path
								d='M11.4998 17.3428C11.8991 17.3428 12.2145 17.235 12.4461 17.0193C12.6777 16.8037 12.7935 16.4883 12.7935 16.073C12.7935 15.6737 12.6817 15.3542 12.4581 15.1146C12.2345 14.8751 11.9151 14.7553 11.4998 14.7553C11.1005 14.7553 10.785 14.8751 10.5534 15.1146C10.3218 15.3542 10.206 15.6737 10.206 16.073C10.206 16.4723 10.3178 16.7837 10.5415 17.0074C10.7651 17.231 11.0845 17.3428 11.4998 17.3428ZM11.5717 13.1501C11.8911 13.1501 12.1507 13.0502 12.3503 12.8506C12.55 12.6509 12.6498 12.3834 12.6498 12.048V7.7834C12.6498 7.46395 12.55 7.20441 12.3503 7.00475C12.1507 6.8051 11.8911 6.70527 11.5717 6.70527C11.2522 6.70527 10.9887 6.8051 10.781 7.00475C10.5734 7.20441 10.4696 7.46395 10.4696 7.7834V12.048C10.4696 12.3834 10.5774 12.6509 10.793 12.8506C11.0086 13.0502 11.2682 13.1501 11.5717 13.1501ZM11.4998 22.0865C10.1102 22.0865 8.80048 21.827 7.57062 21.3079C6.34076 20.7888 5.26663 20.07 4.34823 19.1516C3.42982 18.2332 2.71107 17.1591 2.19198 15.9292C1.67288 14.6994 1.41333 13.3896 1.41333 12.0001C1.41333 10.5945 1.67288 9.28079 2.19198 8.05892C2.71107 6.83704 3.42982 5.76691 4.34823 4.8485C5.26663 3.9301 6.34076 3.20736 7.57062 2.68027C8.80048 2.15319 10.1102 1.88965 11.4998 1.88965C12.8894 1.88965 14.2031 2.15319 15.4409 2.68027C16.6788 3.20736 17.7529 3.9301 18.6633 4.8485C19.5737 5.76691 20.2925 6.84104 20.8196 8.0709C21.3467 9.30076 21.6102 10.6105 21.6102 12.0001C21.6102 13.4056 21.3467 14.7193 20.8196 15.9412C20.2925 17.1631 19.5698 18.2292 18.6514 19.1396C17.7329 20.0501 16.6588 20.7688 15.429 21.2959C14.1991 21.823 12.8894 22.0865 11.4998 22.0865ZM11.4998 19.9063C13.688 19.9063 15.5527 19.1357 17.0941 17.5943C18.6354 16.053 19.406 14.1883 19.406 12.0001C19.406 9.81187 18.6354 7.94711 17.0941 6.40579C15.5527 4.86447 13.688 4.09382 11.4998 4.09382C9.31159 4.09382 7.44684 4.86447 5.90552 6.40579C4.3642 7.94711 3.59354 9.81187 3.59354 12.0001C3.59354 14.1883 4.3642 16.053 5.90552 17.5943C7.44684 19.1357 9.31159 19.9063 11.4998 19.9063Z'
								fill='#FFAC23'
							/>
						</g>
					</svg>

					<p>
						<span>
							Una vez que el catálogo avance de fase, no podrá retroceder.
						</span>
						Si está seguro, presione Mover.
					</p>
				</div>
				<div className='button_wrapper'>
					<button
						onClick={(e) => {
							if(idPhase && validation){
								console.log("Moviendo");
								if(validation.errores_totales === 0){
									movePhase(data.id_promo, idPhase)
									alert("La promoción ha sido movida de fase con éxito.")

									setTimeout(() => {
										window.location.reload();
									}, 1000)
								}else{
									let errores = ""
									validation.errores.forEach((error) => {
										errores += error + "\n"
									});
									alert("La promoción no puede ser movida de fase debido a los siguientes errores: \n" + errores);
								}
								
							}
							setmoverShow(false)
							// handleMoveState(promotionSelect, stateSelect)
						}}
					>
						Mover
					</button>
				</div>
			</form>

			<svg
				className='close_icon_eliminar_show'
				onClick={(e) => {
					setmoverShow(false)
				}}
				width='12'
				height='12'
				viewBox='0 0 12 12'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<mask
					id='mask0_297_6366'
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='12'
					height='12'
				>
					<rect width='12' height='12' fill='#D9D9D9' />
				</mask>
				<g mask='url(#mask0_297_6366)'>
					<path
						d='M6.0251 6.92495L3.6376 9.31245C3.50426 9.44578 3.34593 9.51245 3.1626 9.51245C2.97926 9.51245 2.82093 9.44578 2.6876 9.31245C2.55426 9.17912 2.4876 9.02295 2.4876 8.84395C2.4876 8.66462 2.55426 8.50828 2.6876 8.37495L5.0876 5.97495L2.6751 3.57495C2.54176 3.44162 2.4751 3.28745 2.4751 3.11245C2.4751 2.93745 2.54176 2.78328 2.6751 2.64995C2.80843 2.51662 2.9646 2.44995 3.1436 2.44995C3.32293 2.44995 3.47926 2.51662 3.6126 2.64995L6.0001 5.02495L8.3626 2.66245C8.49593 2.52912 8.65426 2.46245 8.8376 2.46245C9.02093 2.46245 9.17926 2.52912 9.3126 2.66245C9.44593 2.79578 9.5126 2.95612 9.5126 3.14345C9.5126 3.33112 9.44593 3.49162 9.3126 3.62495L6.9376 6.01245L9.3251 8.39995C9.4501 8.52495 9.5126 8.67912 9.5126 8.86245C9.5126 9.04578 9.4501 9.19995 9.3251 9.32495C9.2001 9.44995 9.0481 9.51245 8.8691 9.51245C8.68976 9.51245 8.5376 9.44995 8.4126 9.32495L6.0251 6.92495Z'
						fill='#AEB0B0'
					/>
				</g>
			</svg>
		</div>
	)
}
