import {envVariables} from '../utils/envVariables/envVariables'
import PromoApi from '../utils/config/apiPromobooster'

const token = localStorage.getItem('token')

export const getPreviousSimulation = async (id, estado) => {
    
    // const sessionToken = localStorage.getItem('token')
    // return PromoApi.get(`${envVariables.baseUrl}/simulador/simulacion_previa/`+id, {
      return PromoApi.get(`${envVariables.baseUrl}/simulador/simulacion_previa/v2/`+id+`/`+estado, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          return response
        }).catch((error) => {
          console.log(error)
          return {
            "alertas": {
              "faltan_datos": 0, 
              "productos_bajo_costo": 0, 
              "productos_sin_historia": 0
            }, 
            "data_promocion": {
              "baseline": "-", 
              "canal": 1, 
              "fase_str": "-", 
              "fecha_actualizacion": "06/09/2023 | 12:28", 
              "fecha_ingreso": "06/09/2023", 
              "id": 1, 
              "id_tipo_promocion": 1, 
              "margen_incremental": "-", 
              "mg_incremental": "-", 
              "nombre_cadena": "JUMBO", 
              "nombre_promocion": "TEST SIM 1228 ERROR", 
              "roi": "-", 
              "sku_count": 0, 
              "tipo_canal": "Mayorista", 
              "tipo_promocion": "SELL-IN", 
              "uplift": "-"
            }, 
            "resultado_simulacion": []
          }
          
        });
    }

export const simulatePromotion = async (data, idPromocion, estadoPromocional) => {
      // const response = await fetch(`${envVariables.baseUrl}/simulador/simular`, {
      // const response = await fetch(`${envVariables.baseUrl}/simulador/simular/v2`, {
      const response = await fetch(`${envVariables.baseUrl}/simulador/simular/v2/${idPromocion}/${estadoPromocional}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          "Access-Control-Allow-Origin" : "*", 
          "Access-Control-Allow-Credentials" : true
        },
        body: JSON.stringify(data),
      });
    
      const responseData = await response.json();
      responseData.status = response.status;
      return responseData;
    };
