import React, { useState, useEffect, useRef } from 'react'
import AvatarPop from '../../assets/img/AvatarPop.svg'
import Actividad from '../../assets/img/Actividad.svg'
import Resumen from '../../assets/img/Icono Resumen.svg'
import Cerrar from '../../assets/img/Cerrar.svg'
import Avatargirl from '../../assets/img/Avatargirl.svg'
import { LoaderNew } from '../../components/LoaderNew'
import { config, formatNumberES } from '../../config'
import TableauReport from 'tableau-react'
import {envVariables} from '../../utils/envVariables/envVariables'


function getComments(promotionId) {
	const sessionToken = localStorage.getItem('token')
	// console.log(sessionToken);
	// if there is a session token stored
	if (sessionToken) {
		return fetch(
			envVariables.baseUrl + `/actividad/` + promotionId,
			{
				// mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + sessionToken,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				// console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				// console.log(response)
				return response
			})
	}
	return {
		// return empty json
	}
}

export const CodigoPopNew = ({
	setCodigoPop,
	dataProduct,
	data = null,
	categoria = null,
	subcategoria = null,
	idPromocion,
	promotion,
	tradicional = false,
}) => {
	console.log('Data producto vista producto ----------')
	console.log(dataProduct)
	console.log('Data general vista producto')
	console.log(data)
	const [TextareaFocus, setTextareaFocus] = useState(false)
	const [disableCheck, setdisableCheck] = useState(true)
	const [dataView, setDataView] = useState(null)
	const [dataComments, setDataComments] = useState(null)
	const [tokenTableau, setTokenTableau] = useState(null)
	const [loader, setLoader] = useState(false)
	const [flag, setFlag] = useState(false)
	const [hasFetched, setHasFetched] = useState(false);
	const initialized = useRef(false)
	let url_ = ''
	let parameters_ = {}

	const refCatalogModern = useRef(null)

	function getViewProduct(
		codeProduct,
		customer,
		type = null,
		promotionId = null
	) {
		const sessionToken = localStorage.getItem('token')
		// if there is a session token stored
		setLoader(true)
		if (sessionToken) {
			let urlBase = ``
			if (type === 'tradicional') {
				if (dataProduct.office_code !== null) {
					urlBase = `/productos/view/${codeProduct}/customer/${customer.toUpperCase()}`
				} else {
					urlBase = `/productos/view/${codeProduct}/customer/${customer.toUpperCase()}`
				}
				console.log('url', config.apiUrl + urlBase)
			} else {
				urlBase = `/productos/view/${codeProduct}/customer/${customer.toUpperCase()}`
			}
			return fetch(config.apiUrl + urlBase, {
				method: 'GET',
				// mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + sessionToken,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
					'method': 'POST',
				},
			})
				.then((response) => {
					if (response.ok) {
						return response.json()
					}
				})
				.then((response) => {
					setLoader(false)
					return response
				})
				.catch((error) => {
					setLoader(false)
					console.log('err::: ', error)
				})
		}
	}

	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModern.current.contains(e.target)) {
			setCodigoPop(false)
		}
	}

		console.log("promotion:::::::::")
		console.log(promotion)
		url_ ='https://tableauserver-azure.pricemaker.io/t/Soprole/views/VistaProducto/producto'
		parameters_ = {
			"Codigo Producto": [dataProduct.codigo_producto], 
			"cadena": [promotion.data_promocion.nombre_cadena]
		}
	

	const fetchApi = async () => {
		const url = 'https://optimusprice.pricemaker.io/api/getChartTrust'
		// https://tableauserver-azure.pricemaker.io/t/Soprole/views/basetest/titi2
		const dtargetSite = 'Soprole'
		const project = 'VistaProducto'
		const page = 'producto'

		const response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				token: window.sessionStorage.getItem('token') + '-2chzRY3F3I9@',
				tabs_permission: 2,
				dtargetSite: dtargetSite,
				project: project,
				page: page,
			}),
		})
		console.log("respuesta tableau server::::::::::::::::::.")
		console.log(response.status)
		console.log(response)
		const responseJSON = await response.json()
		setTokenTableau(responseJSON.token_server)
	}

	useEffect(() => {
		if (!initialized.current) {
			fetchApi();
			initialized.current = true
		  }
	}, [])

	useEffect(() => {		
			getViewProduct(
				dataProduct.codigo_producto,
				promotion.data_promocion.nombre_cadena
			)
				.then((result) => {
					setDataView(result)
				})
				.catch((err) => {
					console.err(err)
				})
		
	}, [])


	useEffect(() => {
		getComments(idPromocion).then((result) => {
			setDataComments(result)
			setFlag(false)
		})
		
	}, [flag]) 

	const Callback = async () => {
		const comment = document.getElementById('input_comment').value
		const sessionToken = localStorage.getItem('token')
		if (sessionToken) {
			const response = await fetch(envVariables.baseUrl + `/comment/create`, {
				// mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + sessionToken,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify(
					{
						id_promocion: parseInt(idPromocion),
						comentario: comment,
					},
				),
			}).then((response) => {
				return response
			}).catch((error) => {
				console.log(error)
				alert("Error al crear el comentario");
				document.getElementById('input_comment').value = ''
				document.getElementById('input_comment').disabled = false
				return null
			})
			const responseJSON = await response.json()

			// document.querySelector('#section_comments').innerHTML =  document.querySelector('#section_comments').innerHTML + ReactDOMServer.renderToString(comment_html);
			document.getElementById('input_comment').value = ''
			// setDataComments(dataComments.concat(responseJSON))
			document.getElementById('input_comment').disabled = false
			setFlag(true)
		}
	}

	const onEnter = (event) => {
		if (event.key === 'Enter') {
			document.getElementById('input_comment').disabled = true
			Callback()
		}
	}

	const getStrMonth3 = (monthStr) => {
		const montshObj = {
			Jan: '01',
			Feb: '02',
			Mar: '03',
			Apr: '04',
			May: '05',
			Jun: '06',
			Jul: '07',
			Aug: '08',
			Sep: '09',
			Oct: '10',
			Nov: '11',
			Dec: '12',
		}
		return montshObj[monthStr]
	}

	// const arrAuxMapeName = [
	// 	dataView?.mape_winner_name,
	// 	...dataView.other_mape_names,
	// ]
	// const arrAuxMapeValues = [dataView?.mape, ...dataView?.other_mape_values]

	return (
		<div className='popupWrapper OfertaWrapper TottusPop CodigoPop'>
			{ /* loader && <LoaderNew message={'Cargando...'} /> */ }
			
			{!loader && (
				<div className='popup' ref={refCatalogModern} style={{width: "1600px"}}>
					<div
						className='close_icon'
						onClick={(e) => {
							setCodigoPop(false)
						}}
					>
						<img
							src={Cerrar}
							alt=''
							onClick={(e) => {
								setCodigoPop(false)
							}}
						/>
					</div>
					<div className='left_side' style={{width: "400px"}}>
						<h1>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<mask
									id='mask0_2505_117424'
									maskUnits='userSpaceOnUse'
									x='0'
									y='0'
									width='24'
									height='24'
								>
									<rect width='24' height='24' fill='#D9D9D9' />
								</mask>
								<g mask='url(#mask0_2505_117424)'>
									<path
										d='M2 6H3.83299V19H2V6ZM7.45825 6H8.35438V19H7.45825V6ZM4.72912 6H6.56212V19H4.72912V6ZM19.2709 6H22V19H19.2709V6ZM10.1874 6H11.9796V19H10.1874V6ZM16.5418 6H17.4379V19H16.5418V6ZM12.9165 6H15.6456V19H12.9165V6Z'
										fill='#AEB0B0'
									/>
								</g>
							</svg>
							{dataProduct.codigo_producto}
							<p className='special_p'>{dataProduct.descripcion}</p>
						</h1>

						<table>
							<tbody>
								<tr>
									<td>
										<p>Familia</p>
										<h1>{dataView && dataView.familia}</h1>
									</td>
									<td>
										<p>Sub Familia</p>
										<h1>
										{dataView && dataView.subfamilia}
										</h1>
									</td>
									<td className='border-gray' width='12%'>
										<p>Marca</p>
										<h1>{dataView && dataView.marca}</h1>
									</td>
									<td>
										<p>Peso Caja</p>
										<h1>{dataView && dataView.peso} g</h1>
									</td>
									<td>
										<p>Peso Unidad</p>
										<h1>{dataView && dataView.um}</h1>
									</td>
									<td width='5%'>
										<p>Und</p>
										<h1>{dataView && dataView.stock_und}</h1>
									</td>
									<td width='5%'>
										<p>Vol.</p>
										<h1>{dataView && dataView.stock_kg}</h1>
									</td>
									<td>
										<p>Formato Venta</p>
										<h1>{dataView && dataView.um}</h1>
									</td>
								</tr>
								<tr>
									<td>
										<p>
											Costo <br /> Direc.
										</p>
										<h1>${dataView && dataView.costo_x_kg} (Kg)</h1>
									</td>
									<td>
										<p>
											Cto. <br /> Proyec.
										</p>
										<h1>
											${dataView && dataView.costo_proyectado_kg} (Kg)
										</h1>
									</td>
									
										<td>
											<p style={{ height: '30px' }}>Salas</p>
											<h1>{dataView &&  dataView?.salas}</h1>
										</td>
									
									
										<td width='12%'
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'space-between',
											height: '60px',
										}}
										>
											<p>Catalogación</p>
											<h1><br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{dataView &&  dataView?.catalogacion}</h1>
										</td>
									

									
										
									
									
										<td>
											<p>
												Crec. <br /> Interanual
											</p>
											<h1>{dataView && dataView?.crec_interanual}</h1>
										</td>
									
									
									<td>
										<p>
											Días <br /> Inventario
										</p>
										<h1>{dataView?.dias_inventario}</h1>
									</td>
								</tr>
							</tbody>
						</table>

						

						<h1 style={{ marginTop: 20 }}>
							<img src={Actividad} alt='' />
							Actividad
						</h1>

						<div className='profile_box_wrapper profile_box_wrapperNew'>
							<div className='profile_box' style={{ alignItems: 'flex-start' }}>
								<img src={Avatargirl} alt='' />
								<div className='presentation' style={{ position: 'relative' }}>
									<textarea
										cols='30'
										rows='10'
										className={`${TextareaFocus && 'messageType'}`}
										placeholder='Escribe un comentario...'
										onChange={(e) => {
											if (e.target.value !== '') {
												setdisableCheck(false)
											} else {
												setdisableCheck(true)
											}
										}}
										onFocus={(e) => {
											setTextareaFocus(true)
										}}
										onBlur={(e) => {
											setTextareaFocus(false)
										}}
										onKeyPress={(e) => onEnter(e, 1)}
										id='input_comment'
										autoComplete='off'
										defaultValue={"Comentario producto "+dataProduct.codigo_producto+": "}
									></textarea>
									{/* <button className="comment" disabled={disableCheck} 
                    onClick={(e) => {Callback()}}>
                    Comentar
                  </button> */}
								</div>
							</div>

							<div id='section_comments' className='commentsMax'
						style={{height: '450px', maxHeight: '450px', 
						overflowX: "auto"}}>
							{
							!dataComments
								? ''
								: dataComments.sort((a,b) => b.id_actividad - a.id_actividad).map((EachRow) =>
										EachRow.comentario === 0 ? (
											<div key={EachRow.id_actividad}
												className='profile_box'
												id={'box_comment_' + EachRow.id_actividad}
												style={{ marginBottom: '10px' }}
											>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
															{EachRow.usuario_actividad}
														</h1>
													</div>
													<div className='middle_area'>
														<p>
															{EachRow.actividad}
															
															<span>
															
															</span>
														</p>
													</div>
													<p>
													{EachRow.fase_actividad && EachRow.fase_actividad+" | "} {EachRow.datetime_actividad}
														{
															/* <span
                        onClick={(e) => {
                          // deleteComment(EachRow.id_comment)
                        }}
                        style={{marginRight:"0px"}}
					>Eliminar</span> */}
													</p>
												</div>
											</div>
										) : (
											<div className='profile_box responder' key={EachRow.id_actividad}>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
														{EachRow.usuario_actividad}
														</h1>
														<p>
														{EachRow.fase_actividad && EachRow.fase_actividad+" | "} {EachRow.datetime_actividad}
														</p>
													</div>
													<input
														type='text'
														placeholder={EachRow.actividad}
														value={EachRow.actividad}
													/>
													
												</div>
											</div>
										)
								  )}
						</div>
						</div>
					</div>
					<div className='right_side' style={{width: "1100px"}}>
						<div className='gray_area' style={{ background: 'transparent' }}>
							{!tokenTableau ? (
								'Cargando Dashboard...'
							) : (
								/*
								<TableauReport
									url={url_}
									token={tokenTableau}
									options={{
										width: "100%",
										height: "95vh",
									}}
									query="?:jsdebug=n"
									parameters={parameters_}
								/>
								*/
								<iframe
									src={'/Dashboardclean/'+dataProduct.codigo_producto+'/'+promotion.data_promocion.nombre_cadena}
									width='1050px'
									height='100%'
									frameBorder='0'
									title='Dashboard'
								></iframe>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
