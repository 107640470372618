import {envVariables} from './utils/envVariables/envVariables'
export const config = {
	// apiUrl: "http://172.17.0.2:5000"
	apiUrl: envVariables.baseUrl,
}

export const formatNumberES = (n, d = 0) => {
	console.log('EJECUNTADO FOMATEOOOO!!!')
	try {
		n = new Intl.NumberFormat('es-ES').format(
			parseFloat(n.replace('.', '').replace(',', '.')).toFixed(d)
		)
		console.log('final fomateo ' + n)
	} catch (error) {
		n = new Intl.NumberFormat('es-ES').format(parseFloat(n).toFixed(d))
		console.log('final fomateo ' + n)
	}

	if (String(n).replace('.', '').length === 4) {
		n = n.charAt(0) + '.' + n.substr(1, 3)
		console.log('NNN formateo excepcional ' + n)
	}

	if (d > 0) {
		// Obtenemos la cantidad de decimales que tiene el numero
		const decimals = n.indexOf(',') > -1 ? n.length - 1 - n.indexOf(',') : 0

		// añadimos los ceros necesios al numero
		n = decimals === 0 ? n + ',' + '0'.repeat(d) : n + '0'.repeat(d - decimals)
	}

	return n
}