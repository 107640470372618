import {types} from './types'

export const authReducer = (state = {}, action) => {
	console.log(action)
	switch (action.type) {
		case types.request:
			return {
				...state,
				loading: true,
			}

		case types.login:
			return {
				...state,
				logged: true,
				loading: false,
				user: action.payload,
			}

		case types.logout:
			return {
				...state,
				user: '',
				token: '',
				loading: false,
				logged: false,
			}

		case types.error:
			return {
				...state,
				loading: false,
				errorMessage: action.payload,
			}

		default:
			return state
	}
}
