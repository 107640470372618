import React, { useState, useEffect } from 'react'
import { IngresoPromocionDropDown } from './IngresoPromocionDropDown'
import { IngresoPromocionDropDownDate } from './IngresoPromocionDropDownDate'
import close from '../../assets/img/close.svg'
import './input.css'
import { numeroaDinero, numeroaDescuento, dineroaNumero, descuentoaNumero } from './helper'
import { useParams} from 'react-router-dom'


export const LengendColumnsInput = ({ approved = null, id, legendColumns, setLegendColumns,
	promotion,
	codigoProducto,
	descripcion,
	precioLista,
	precioPromocion,
	descuento,
	mecanica,
	adherencia,
	exhibicion,
	activacion,
	invAdicional,
	mgCliente,
	pvp,
	inicioSellin,
	terminoSellin,
	inicioSellout,
	terminoSellout,
	setIdProductoEliminar,
	tipoPromocion,
	keyId,
	setInicioPopState,
	setIdEliminar,
	view,
	rejected = null,
	estadoProducto = null,
	idProducto = null,
	product=null,
	setModalProduct,
	setDataProduct
}) => {
	const getExhibicion = (id) => {
		let result = '';
		promotion.exhibiciones.forEach((exhibicion) => {
			if (exhibicion.id === id) {
				result = exhibicion.exhibicion;
			}
		});
		return result;
	}

	const [valPrecioPromocion, setValPrecioPromocion] = useState(false);
	const [valDescuento, setValDescuento] = useState(false);
	const [valPVP, setValPVP] = useState(false);
	const [valMGCliente, setValMGCliente] = useState(false);
	const [valAdherencia, setValAdherencia] = useState(false);
	const [valInvAdicional, setValInvAdicional] = useState(false);
	const [mecanicaDropDown, setMecanicaDropDown] = useState(mecanica);
	const [exhibicionDropDown, setExhibicionDropDown] = useState(getExhibicion(exhibicion));
	const [activacionDropDown, setActivacionDropDown] = useState(activacion);
	const [inicioSellinDropDown, setInicioSellinDropDown] = useState(inicioSellin);
	const [terminoSellinDropDown, setTerminoSellinDropDown] = useState(terminoSellin);
	const [inicioSelloutDropDown, setInicioSelloutDropDown] = useState(inicioSellout);
	const [terminoSelloutDropDown, setTerminoSelloutDropDown] = useState(terminoSellout);
	const [descuentoInput, setDescuentoInput] = useState(descuento);
	const [changes, setChanges] = useState(null)
	const { estadoPromocion } = useParams()
	const [estadoProducto_, setEstadoProducto_] = useState(estadoProducto)
	const [productos, setProductos] = useState([]);
	const [clickHere, setClickHere] = useState(false)
	// alert(estadoPromocion)
	const actualizarProductos = () => {
		const gestionProductos = localStorage.getItem('gestionProductos');
		// alert(gestionProductos)
		if (gestionProductos) {
		  setProductos(JSON.parse(gestionProductos));
		} else {
		  setProductos([]);
		}
	};

	const productoExiste = (idProducto_) => {
		return productos.filter(producto => producto.id_producto === idProducto_)[0];
	}

	useEffect(() => {
		// Inicialmente obtener los productos
		// alert("nocorre")
		actualizarProductos();
		const producto = productoExiste(idProducto);
		console.log("Producto:::::::::::::::::::::Seleccionado A Cambiar:::::::")
		console.log(producto)
		if (producto && !clickHere) {
			if (producto.accion === 'aprobado') {
				// alert("aprobado")
				estadoProducto = 8
				product.estado_promocional = 8
				product.id_estado_promocional = 8
				setEstadoProducto_(8)
			} else if (producto.accion === 'rechazado') {
				// alert("rechazado")
				estadoProducto = 7
				product.estado_promocional = 7
				product.id_estado_promocional = 7
				setEstadoProducto_(7)
			} else if (producto.accion === 'cambio') {
				setEstadoProducto_(-1)
			}
		}
		setClickHere(false)
		window.addEventListener("storage", actualizarProductos);
		return () => {
			// Remove the handler when the component unmounts
			window.removeEventListener("storage", actualizarProductos);
		};
	  }, [localStorage.getItem('gestionProductos')]);

	
	

	const activaciones = [];
	const exhibiciones = [];
	const mecanicas = [];

	console.log("Promocion:::::::::::::::::::::::::::::Array Content:::::::::: ");
	console.log(promotion);
	promotion.mecanicas.forEach((mecanica) => {
		mecanicas.push(mecanica.mecanica);
	});
	promotion.exhibiciones.forEach((exhibicion) => {
		exhibiciones.push(exhibicion.exhibicion);
	});
	promotion.activaciones.forEach((activacion) => {
		activaciones.push(activacion.activacion);
	});



	const calculoPrecio = (e) => {
		const campo = e.target.id.split("_")[0];
		const restId = e.target.id.split("_")[1] + "_" + e.target.id.split("_")[2];
		let descuento = parseFloat(0).toFixed(2);

		if (tipoPromocion === 'SELL-IN') {
			if (campo === 'preciopromocion') {
				// alert(restId);
				const precioLista_ = parseFloat(precioLista).toFixed(0);
				const precioPromocion_ = parseFloat(dineroaNumero(document.getElementById("preciopromocion_" + restId).value)).toFixed(0);
				descuento = parseFloat((1 - (precioPromocion_ / precioLista_)) * 100).toFixed(2);
				let mgCliente_ = parseFloat(descuentoaNumero(document.getElementById("mgcliente_" + restId).value)).toFixed(2);
				if (mgCliente_ === 'NaN' || mgCliente_ === 'Infinity'
					|| mgCliente_ === 'undefined' || mgCliente_ === 'null' || mgCliente_ === ''
				) {
					mgCliente_ = 0;
				}
				let pvp_ = 0;
				if(String(product.um).toUpperCase() === 'KG'){
					// trans
					console.log("trans")
					pvp_ = Math.round((precioPromocion_* product.factor * 1.19) / (1 - (mgCliente_ / 100)));
				}else{
					// EL original es esta línea
					pvp_ = Math.round((precioPromocion_ * 1.19) / (1 - (mgCliente_ / 100)));
				}
				document.getElementById("descuento_" + restId).value = numeroaDescuento(descuento);
				document.getElementById("pvp_" + restId).value = numeroaDinero(pvp_);
				document.getElementById("mgcliente_" + restId).value = numeroaDescuento(mgCliente_);
				document.getElementById("preciopromocion_" + restId).value = numeroaDinero(precioPromocion_);
				if (descuento <= 0 || descuento >= 100) {
					setValDescuento(true);
				}
				if (pvp_ <= 0) {
					setValPVP(true);
				}
				if (mgCliente_ <= -500 || mgCliente_ >= 500) {
					setValMGCliente(true);
				}
				if (precioPromocion_ <= 0 || precioPromocion_ > precioLista) {
					setValPrecioPromocion(true);
				}

			} else if (campo === 'descuento') {
				const precioLista_ = parseFloat(precioLista).toFixed(0);
				let descuento_ = parseFloat(descuentoaNumero(document.getElementById("descuento_" + restId).value)).toFixed(2);
				// alert(descuento_);
				if (descuento_ === 'NaN') {
					console.log("alerta real descuento 222 "+descuento);
					descuento_ = 0;
				}
				const precioPromocion_ = Math.round(precioLista_ * (1 - (descuento_ / 100)));
				// document.getElementById("descuento_"+restId).value = parseFloat(descuento_).toFixed(2);
				let mgCliente_ = parseFloat(descuentoaNumero(document.getElementById("mgcliente_" + restId).value)).toFixed(2);
				if (mgCliente_ === 'NaN' || mgCliente_ === 'Infinity'
					|| mgCliente_ === 'undefined' || mgCliente_ === 'null' || mgCliente_ === ''
				) {
					mgCliente_ = 0;
				}
				let pvp_ = 0;
				if(String(product.um).toUpperCase() === 'KG'){
					// trans
					console.log("trans")
					pvp_ = Math.round((precioPromocion_* product.factor * 1.19) / (1 - (mgCliente_ / 100)));
				}else{
					// EL original es esta línea
					pvp_ = Math.round((precioPromocion_ * 1.19) / (1 - (mgCliente_ / 100)));
				}

				document.getElementById("preciopromocion_" + restId).value = numeroaDinero(precioPromocion_);
				document.getElementById("descuento_" + restId).value = numeroaDescuento(descuento_);
				document.getElementById("pvp_" + restId).value = numeroaDinero(pvp_);
				document.getElementById("mgcliente_" + restId).value = numeroaDescuento(parseFloat(mgCliente_).toFixed(2));
				if (descuento_ <= 0 || descuento_ >= 100) {
					console.log("alerta real descuento "+descuento_);
					setValDescuento(true);
				}
				if (pvp_ <= 0) {
					setValPVP(true);
				}
				if (mgCliente_ <= -500 || mgCliente_ >= 500) {
					setValMGCliente(true);
				}
				if (precioPromocion_ <= 0 || precioPromocion_ > precioLista) {
					setValPrecioPromocion(true);
				}
			} else if (campo === "mgcliente") {
				const precioPromocion_ = parseFloat(dineroaNumero(document.getElementById("preciopromocion_" + restId).value)).toFixed(0);
				const mgCliente_ = parseFloat(descuentoaNumero(document.getElementById("mgcliente_" + restId).value)).toFixed(2);

				let pvp_ = 0;
				if(String(product.um).toUpperCase() === 'KG'){
					// trans
					console.log("trans")
					pvp_ = Math.round((precioPromocion_* product.factor * 1.19) / (1 - (mgCliente_ / 100)));
				}else{
					// EL original es esta línea
					pvp_ = Math.round((precioPromocion_ * 1.19) / (1 - (mgCliente_ / 100)));
				}
				document.getElementById("pvp_" + restId).value = numeroaDinero(pvp_);
				document.getElementById("mgcliente_" + restId).value = numeroaDescuento(mgCliente_);

				if (pvp_ <= 0) {
					setValPVP(true);
				}

				if (mgCliente_ <= -500 || mgCliente_ >= 500) {
					setValMGCliente(true);
				}

			} else if (campo === "pvp") {
				const precioPromocion_ = parseFloat(dineroaNumero(document.getElementById("preciopromocion_" + restId).value)).toFixed(0);
				const pvp_ = parseFloat(dineroaNumero(document.getElementById("pvp_" + restId).value)).toFixed(0);
				let mgCliente_ = 0;
				if(String(product.um).toUpperCase() === 'KG'){
					// trans
					console.log("trans")
					mgCliente_ = parseFloat((1 - ((precioPromocion_ * product.factor * 1.19) / pvp_)) * 100).toFixed(2);
				}else{
					// EL original es esta línea
					mgCliente_ = parseFloat((1 - ((precioPromocion_ * 1.19) / pvp_)) * 100).toFixed(2);
				}
				// const mgCliente_ = parseFloat((1 - ((precioPromocion_ * 1.19) / pvp_)) * 100).toFixed(2);
				document.getElementById("mgcliente_" + restId).value = numeroaDescuento(mgCliente_);
				document.getElementById("pvp_" + restId).value = numeroaDinero(pvp_);
				if (pvp_ <= 0) {
					setValPVP(true);
				}
				if (mgCliente_ <= -500 || mgCliente_ >= 500) {
					setValMGCliente(true);
				}
			}
		} else { // SELLOUT
			if (campo === 'descuento' || campo === 'mgcliente') {// Precio promocion no hay acá, ahora es precio fleje
				const precioLista_ = parseFloat(precioLista).toFixed(0);
				const precioFleje = parseFloat(dineroaNumero(document.getElementById("preciopromocion_" + restId).value)).toFixed(0);
				let descuento_ = parseFloat(descuentoaNumero(document.getElementById("descuento_" + restId).value)).toFixed(2);
				if (descuento_ === 'NaN') {
					descuento_ = 0;
				}
				// const resultado_ = Math.round(precioLista_ - (precioLista_ * descuento_ / 100));
				// document.getElementById("preciopromocion_"+restId).value = precioPromocion_;
				// document.getElementById("descuento_"+restId).value = parseFloat(descuento_).toFixed(2);
				let mgCliente_ = parseFloat(descuentoaNumero(document.getElementById("mgcliente_" + restId).value)).toFixed(2);
				if (mgCliente_ === 'NaN' || mgCliente_ === 'Infinity'
					|| mgCliente_ === 'undefined' || mgCliente_ === 'null' || mgCliente_ === ''
				) {
					mgCliente_ = 0;
				}
				let newPrecioFleje_ = 0;
				if(String(product.um).toUpperCase() === 'KG'){
					// trans
					console.log("trans")
					newPrecioFleje_ = Math.round((precioLista_  * product.factor * 1.19) / (1 - (mgCliente_ / 100)));
				}else{
					// EL original es esta línea
					newPrecioFleje_ = Math.round((precioLista_ * 1.19) / (1 - (mgCliente_ / 100)));
				}
				// const newPrecioFleje_ = Math.round((precioLista_ * 1.19) / (1 - (mgCliente_ / 100)));
				document.getElementById("preciopromocion_" + restId).value = numeroaDinero(newPrecioFleje_);
				if (newPrecioFleje_ <= 0) {
					setValPrecioPromocion(true);
				}
				if (campo !== "mgcliente") {
					document.getElementById("mgcliente_" + restId).value = numeroaDescuento(parseFloat(mgCliente_).toFixed(2));
					document.getElementById("descuento_" + restId).value = numeroaDescuento(parseFloat(descuento_).toFixed(2));

					if (mgCliente_ <= -500 || mgCliente_ >= 500) {
						setValMGCliente(true);
					}
				} else if (campo !== "descuento") {
					document.getElementById("mgcliente_" + restId).value = numeroaDescuento(parseFloat(mgCliente_).toFixed(2));
					document.getElementById("descuento_" + restId).value = numeroaDescuento(parseFloat(descuento_).toFixed(2));
					if (descuento_ <= 0 || descuento_ >= 100) {
						setValDescuento(true);
					}
				}
				// let pvpPromocion = 0;
				/*
				if(String(product.um).toUpperCase() === 'KG'){
					// trans
					console.log("trans")
					pvpPromocion = Math.round(newPrecioFleje_ * product.factor * (1 - (descuento_ / 100)));
				}else{
					// EL original es esta línea
					pvpPromocion = Math.round(newPrecioFleje_ * (1 - (descuento_ / 100)));
				} */
				const pvpPromocion = Math.round(newPrecioFleje_ * (1 - (descuento_ / 100)));
				document.getElementById("pvp_" + restId).value = numeroaDinero(pvpPromocion);
				if (pvpPromocion <= 0) {
					setValPVP(true);
				}
			} else if (campo === "pvp") {
				const pvpPromocional = parseFloat(dineroaNumero(document.getElementById("pvp_" + restId).value)).toFixed(0);
				const precioFleje_ = parseFloat(dineroaNumero(document.getElementById("preciopromocion_" + restId).value)).toFixed(0);
				// const descuento_ = parseFloat(document.getElementById("descuento_"+restId).value).toFixed(2);
				// const mgCliente_ = -1 * (((parseInt(pvpPromocional)) / parseInt(precioFleje_)) - 1);
				const mgCliente_ = parseFloat(descuentoaNumero(document.getElementById("mgclienteField_"+ restId).value)).toFixed(2);// -1 * (((parseInt(pvpPromocional)) / parseInt(precioFleje_)) - 1);
				// const pvp_ = Math.round(precioPromocion_ / (1 - (mgCliente_ / 100)));
				// document.getElementById("mgcliente_" + restId).value = numeroaDescuento(mgCliente_);
				document.getElementById("pvp_" + restId).value = numeroaDinero(pvpPromocional);

				// let descuento = 0;
				/*
				if(String(product.um).toUpperCase() === 'KG'){
					descuento = parseFloat((1 - (pvpPromocional / (precioFleje_ * product.factor))) * 100).toFixed(2);
				}else{
					descuento = parseFloat((1 - (pvpPromocional / precioFleje_)) * 100).toFixed(2);
				} */
				const descuento = parseFloat((1 - (pvpPromocional / precioFleje_)) * 100).toFixed(2);
				
				document.getElementById("descuento_" + restId).value = numeroaDescuento(descuento);

				if(descuento <= 0 || descuento >= 100){
					setValDescuento(true);
				}else{
					setValDescuento(false);
				}

				if (pvpPromocional <= 0) {
					setValPVP(true);
				}
				if (mgCliente_ <= -500 || mgCliente_ >= 500) {
					setValMGCliente(true);
				}
			}// precio promocion está bloqueado
		}
	}

	const gestionarProducto = (e, estado) => {
		const prevGestionProductos = localStorage.getItem('gestionProductos');
		let gestionProductos = prevGestionProductos ? JSON.parse(prevGestionProductos) : [];
		gestionProductos = gestionProductos.filter(producto => producto.id_producto !== idProducto);

		gestionProductos.push({
							'id_promocion': promotion.data_promocion.id,
							'id_producto': idProducto,// keyId,
							'accion': estado
						});

						localStorage.setItem('gestionProductos', JSON.stringify(gestionProductos));
						window.dispatchEvent(new Event("storage"));
	}

	return (
		<>
		{ /* <CodigoPopNew
			// setCodigoPop,
			dataProduct = {product}
			idPromocion = {promotion.data_promocion.id}
			promotion = {promotion}
			tradicional = {false}
	/>   */ }
		<div
			className={`LengendColumnsInput ${estadoProducto_ === 8 && 'LengendColumnsInputApproved'
				}
				${estadoProducto_ === 7 && 'LengendColumnsInputRejected'
				}
				${estadoProducto_ === -1 && 'LengendColumnsInputChanges'
				}
				`}
			id={"ColumnInput_" + id}
		>

			<div className='first-height'>
				{estadoProducto_ === 8 && <p className='approved-text'>Aprobado</p>}
				{estadoProducto_ === 7 && <p className='approved-text'>Rechazado</p>}
				{changes && <p className='approved-text'>Sol. Cambios</p>}
				{!view && (<>
					{ /*
					<svg
						width='32'
						height='24'
						viewBox='0 0 32 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						
					>
						<path d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24H0V8Z' />
						<mask
							id='mask0_640_389992'
							maskUnits='userSpaceOnUse'
							x='8'
							y='4'
							width='16'
							height='16'
						>
							<rect x='8' y='4' width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_640_389992)' >
							<path
								d='M15.9999 13.0666L12.7999 16.2666C12.6555 16.411 12.4777 16.4833 12.2666 16.4833C12.0555 16.4833 11.8777 16.411 11.7333 16.2666C11.5888 16.1222 11.5166 15.9444 11.5166 15.7333C11.5166 15.5222 11.5888 15.3444 11.7333 15.1999L14.9333 11.9999L11.7333 8.79993C11.5888 8.65549 11.5166 8.47771 11.5166 8.2666C11.5166 8.05549 11.5888 7.87771 11.7333 7.73327C11.8777 7.58882 12.0555 7.5166 12.2666 7.5166C12.4777 7.5166 12.6555 7.58882 12.7999 7.73327L15.9999 10.9333L19.1999 7.73327C19.3444 7.58882 19.5222 7.5166 19.7333 7.5166C19.9444 7.5166 20.1222 7.58882 20.2666 7.73327C20.411 7.87771 20.4833 8.05549 20.4833 8.2666C20.4833 8.47771 20.411 8.65549 20.2666 8.79993L17.0666 11.9999L20.2666 15.1999C20.411 15.3444 20.4833 15.5222 20.4833 15.7333C20.4833 15.9444 20.411 16.1222 20.2666 16.2666C20.1222 16.411 19.9444 16.4833 19.7333 16.4833C19.5222 16.4833 19.3444 16.411 19.1999 16.2666L15.9999 13.0666Z'
								fill='#ADADAD'
							/>
						</g>
					</svg>
					<svg
						width='32'
						height='24'
						viewBox='0 0 32 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						onClick={(e) => {
							setIdEliminar(id)
							setInicioPopState(true)
						}}
					>
						<path d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24H0V8Z' />
						<mask
							id='mask0_640_389992'
							maskUnits='userSpaceOnUse'
							x='8'
							y='4'
							width='16'
							height='16'
						>
							<rect x='8' y='4' width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_640_389992)' >
							<path
								d='M15.9999 13.0666L12.7999 16.2666C12.6555 16.411 12.4777 16.4833 12.2666 16.4833C12.0555 16.4833 11.8777 16.411 11.7333 16.2666C11.5888 16.1222 11.5166 15.9444 11.5166 15.7333C11.5166 15.5222 11.5888 15.3444 11.7333 15.1999L14.9333 11.9999L11.7333 8.79993C11.5888 8.65549 11.5166 8.47771 11.5166 8.2666C11.5166 8.05549 11.5888 7.87771 11.7333 7.73327C11.8777 7.58882 12.0555 7.5166 12.2666 7.5166C12.4777 7.5166 12.6555 7.58882 12.7999 7.73327L15.9999 10.9333L19.1999 7.73327C19.3444 7.58882 19.5222 7.5166 19.7333 7.5166C19.9444 7.5166 20.1222 7.58882 20.2666 7.73327C20.411 7.87771 20.4833 8.05549 20.4833 8.2666C20.4833 8.47771 20.411 8.65549 20.2666 8.79993L17.0666 11.9999L20.2666 15.1999C20.411 15.3444 20.4833 15.5222 20.4833 15.7333C20.4833 15.9444 20.411 16.1222 20.2666 16.2666C20.1222 16.411 19.9444 16.4833 19.7333 16.4833C19.5222 16.4833 19.3444 16.411 19.1999 16.2666L15.9999 13.0666Z'
								fill='#ADADAD'
							/>
						</g>
					</svg>
					*/ }
					
			{ ( (estadoPromocion === '5' || estadoPromocion === '7')  
				&& (
						localStorage.getItem('rol') === 'ADMIN' ||
						localStorage.getItem('rol') === 'SUPER ADMIN' 
				)
			) ||

			( estadoPromocion === '9'  
			&& (
					localStorage.getItem('rol') === 'ADMIN' ||
					localStorage.getItem('rol') === 'SUPER ADMIN' || 
					localStorage.getItem('rol') === 'KAM' ||
					localStorage.getItem('rol') === 'KAS' 
			)
		) 
			? (
					<>
					<svg
					width="32"
					height="24"
					viewBox="0 0 32 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={(e) => {
						// setIdEliminar(id);
						// setInicioPopState(true);
						estadoProducto = 8
						product.estado_promocional = 8
						product.id_estado_promocional = 8
						gestionarProducto(e, "aprobado")
						setClickHere(true)	
						setEstadoProducto_(8)

					}}
					>	
					<path d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24H0V8Z' />
						<mask
							id='mask0_640_389992'
							maskUnits='userSpaceOnUse'
							x='8'
							y='4'
							width='16'
							height='16'
						>
							<rect x='8' y='4' width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_640_389992)' >
							<path
								d='M12 18L8 14L9.5 12.5L12 15L18.5 8.5L20 10L12 18Z'
								fill='#ADADAD'
							/>
						</g>
					{ /*
						<path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24H0V8Z" fill="#F0F0F0"/>
						<mask id="mask0_640_389992" maskUnits="userSpaceOnUse" x="8" y="4" width="16" height="16">
							<rect x="8" y="4" width="16" height="16" fill="#D9D9D9"/>
						</mask>
						<g mask="url(#mask0_640_389992)">
							<path d="M11 13L14 16L21 9L19.5 7.5L14 13L12.5 11.5L11 13Z" fill="#4CAF50"/>  
						</g> */ }
					</svg>
					<div className='tool-tip-app'>
						<p>
							Aprobar Producto
						</p>
					</div>
					
					<svg
						width='32'
						height='24'
						viewBox='0 0 32 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						onClick={(e) => {
							estadoProducto = 7
							product.estado_promocional = 7
							product.id_estado_promocional = 7
							gestionarProducto(e, "rechazado")	
							setClickHere(true)
							setEstadoProducto_(7)
						}}
					>
						<path d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24H0V8Z' />
						<mask
							id='mask0_640_389992'
							maskUnits='userSpaceOnUse'
							x='8'
							y='4'
							width='16'
							height='16'
						>
							<rect x='8' y='4' width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_640_389992)' >
							<path
								d='M15.9999 13.0666L12.7999 16.2666C12.6555 16.411 12.4777 16.4833 12.2666 16.4833C12.0555 16.4833 11.8777 16.411 11.7333 16.2666C11.5888 16.1222 11.5166 15.9444 11.5166 15.7333C11.5166 15.5222 11.5888 15.3444 11.7333 15.1999L14.9333 11.9999L11.7333 8.79993C11.5888 8.65549 11.5166 8.47771 11.5166 8.2666C11.5166 8.05549 11.5888 7.87771 11.7333 7.73327C11.8777 7.58882 12.0555 7.5166 12.2666 7.5166C12.4777 7.5166 12.6555 7.58882 12.7999 7.73327L15.9999 10.9333L19.1999 7.73327C19.3444 7.58882 19.5222 7.5166 19.7333 7.5166C19.9444 7.5166 20.1222 7.58882 20.2666 7.73327C20.411 7.87771 20.4833 8.05549 20.4833 8.2666C20.4833 8.47771 20.411 8.65549 20.2666 8.79993L17.0666 11.9999L20.2666 15.1999C20.411 15.3444 20.4833 15.5222 20.4833 15.7333C20.4833 15.9444 20.411 16.1222 20.2666 16.2666C20.1222 16.411 19.9444 16.4833 19.7333 16.4833C19.5222 16.4833 19.3444 16.411 19.1999 16.2666L15.9999 13.0666Z'
								fill='#ADADAD'
							/>
						</g>
					</svg>
					<div className='tool-tip-del'>
						<p>Rechazar Producto</p>
					</div>
					</>
				)
			:	(estadoPromocion === "1" || estadoPromocion === "3") && (<>
				<svg
						width='32'
						height='24'
						viewBox='0 0 32 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						onClick={(e) => {
							setIdEliminar(id+"_"+keyId)
							// setIdEliminar(keyId)
							setInicioPopState(true)
						}}
					>
						<path d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24H0V8Z' />
						<mask
							id='mask0_640_389992'
							maskUnits='userSpaceOnUse'
							x='8'
							y='4'
							width='16'
							height='16'
						>
							<rect x='8' y='4' width='16' height='16' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_640_389992)' >
							<path
								d='M15.9999 13.0666L12.7999 16.2666C12.6555 16.411 12.4777 16.4833 12.2666 16.4833C12.0555 16.4833 11.8777 16.411 11.7333 16.2666C11.5888 16.1222 11.5166 15.9444 11.5166 15.7333C11.5166 15.5222 11.5888 15.3444 11.7333 15.1999L14.9333 11.9999L11.7333 8.79993C11.5888 8.65549 11.5166 8.47771 11.5166 8.2666C11.5166 8.05549 11.5888 7.87771 11.7333 7.73327C11.8777 7.58882 12.0555 7.5166 12.2666 7.5166C12.4777 7.5166 12.6555 7.58882 12.7999 7.73327L15.9999 10.9333L19.1999 7.73327C19.3444 7.58882 19.5222 7.5166 19.7333 7.5166C19.9444 7.5166 20.1222 7.58882 20.2666 7.73327C20.411 7.87771 20.4833 8.05549 20.4833 8.2666C20.4833 8.47771 20.411 8.65549 20.2666 8.79993L17.0666 11.9999L20.2666 15.1999C20.411 15.3444 20.4833 15.5222 20.4833 15.7333C20.4833 15.9444 20.411 16.1222 20.2666 16.2666C20.1222 16.411 19.9444 16.4833 19.7333 16.4833C19.5222 16.4833 19.3444 16.411 19.1999 16.2666L15.9999 13.0666Z'
								fill='#ADADAD'
							/>
						</g>
					</svg>
					<div className='tool-tip-del'>
						<p>Eliminar Producto</p>
					</div>
					</>
				
			)
			
			}

			
				</>)}
			</div>
			<div className='columns-area'>
				<button className='column-box'>
					<a  // style={{pointerEvents: "none"}}
						style={{'cursor': 'pointer'}}
						onClick={(e) => {
							// alert("apertura vista producto")
							setModalProduct(true)
							setDataProduct(product)
						}}
					>{codigoProducto}</a>
				</button>
				<button className='column-box'>
					<small>{descripcion}
						<input type='hidden' id={"descripcion_" + id} value={descripcion} />
					</small>
				</button>
				<button className='column-box' style={{ textAlign: 'right' }}>
					{numeroaDinero(precioLista)
					}
					<input type='hidden' id={"precioLista_" + id} value={precioLista} />
				</button>
				<p className='heading-column'>Producto</p>

				{/* <button className='column-box'>
					<IngresoPromocionDropDown
						head='Tipo'
						list={['Sell In', 'Sell Out']}
					/>
				</button> */}
				<button className='column-box'>
					<div className={`input-box ${valPrecioPromocion ? 'inputError' : ''}`}
						id={"preciopromocionField_" + id}>
						<span></span>
						<input type='text' className='inputStyle'
							defaultValue={numeroaDinero(precioPromocion)}
							id={"preciopromocion_" + id}
							onChange={(e) => {
								calculoPrecio(e)
								if ((dineroaNumero(e.target.value) > precioLista
									|| dineroaNumero(e.target.value) <= 0)
									&& tipoPromocion !== 'SELL-OUT'
								) {
									setValPrecioPromocion(true);
									setValDescuento(true);
								} else {
									setValPrecioPromocion(false);
									setValDescuento(false);
								}
								// numeroaDinero(e.target.value)
							}}
							disabled={tipoPromocion === 'SELL-OUT' || view && "disabled" || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
							// disabled={(estadoPromocion !== 1 && estadoPromocion !== 3 && localStorage.getItem('rol') === 'KAM')}
						/>
					</div>
					{estadoProducto_  === 8 && (
						<div className='popup-approved'>
							<img src={close} alt='' />
							<h1>Este producto está Aprobado</h1>
							<p>
								El descuento asociado a este producto se encuentra dentro de los
								rangos de aprobación automática. Si lo modificas ya no se
								considerará aprobado y un Aprobador deberá revisarlo.
							</p>
							<button>Entendido</button>
							<div className='Polygon-1'></div>
						</div>
					)}
				</button>
				<button className='column-box'>
					<div className={`input-box ${valDescuento ? 'inputError' : ''}`}
						id={"descuentoField_" + id}>



						<input type='text' className='inputStyle'
							defaultValue={numeroaDescuento(descuentoInput)}
							id={"descuento_" + id}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									e.stopPropagation();
									calculoPrecio(e)
									// e.target.value = parseFloat(e.target.value).toFixed(2);
									return false;
								}
							}}
							onBlur={(e) => {
								calculoPrecio(e)
							}}

							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^\d.,]/g, '');
								setDescuentoInput(e.target.value);
								if (descuentoaNumero(e.target.value) > 100
									|| descuentoaNumero(e.target.value) <= 0
								) {
									// alert("ALERTA descuento");
									if(tipoPromocion !== 'SELL-OUT'){
										setValPrecioPromocion(true);
									}
									setValDescuento(true);
								} else {
									setValPrecioPromocion(false);
									setValDescuento(false);
								}
							}}
							disabled={(view && "disabled") || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						/>

						<span>%</span>
					</div>
					{estadoProducto_ === 8 && (
						<div className='popup-approved'>
							<img src={close} alt='' />
							<h1>Este producto está Aprobado</h1>
							<p>
								El descuento asociado a este producto se encuentra dentro de los
								rangos de aprobación automática. Si lo modificas ya no se
								considerará aprobado y un Aprobador deberá revisarlo.
							</p>
							<button>Entendido</button>
							<div className='Polygon-1'></div>
						</div>
					)}
				</button>
				<p className='heading-column'>Producto</p>

				<button className='column-box' >
					<IngresoPromocionDropDown
						head={mecanicaDropDown ?? 'Mecánica'}
						list={mecanicas}
						id={"mecanica_" + id}
						disabled={(view && true) || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						idCompuesto={"mecanicaField_" + id}
						setValueDropDown={setMecanicaDropDown}
					/>
					<input type='hidden' id={"mecanica_" + id} value={mecanicaDropDown} />
				</button>
				<button className='column-box'>
					<div className={`input-box ${valAdherencia ? 'inputError' : ''}`}
						id={"adherenciaField_" + id}>
						<input type='text' className='inputStyle'
							defaultValue={numeroaDescuento(adherencia)}
							id={"adherencia_" + id}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									e.stopPropagation();
									calculoPrecio(e)
									e.target.value = numeroaDescuento(descuentoaNumero(e.target.value));
									// return false;
								}
							}}
							onBlur={(e) => {
								e.target.value = numeroaDescuento(descuentoaNumero(e.target.value));
							}}
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^\d.,]/g, '')
								if (descuentoaNumero(e.target.value) > 100
									|| descuentoaNumero(e.target.value) <= 0
								) {
									setValAdherencia(true);
								} else {
									setValAdherencia(false);
								}
							}}

							disabled={
								((view || mecanica === 1 || mecanica === "1xY") && "disabled")
								|| (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')
							}
						/>
						<span>%</span>
					</div>
				</button>
				<button className='column-box'>
					<IngresoPromocionDropDown
						head={exhibicionDropDown}
						list={exhibiciones}
						id={"exhibicion_" + id}
						disabled={(view && true) || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						idCompuesto={"exhibicionField_" + id}
						setValueDropDown={setExhibicionDropDown}
					/>
					<input type="hidden" id={"exhibicion_" + id} value={exhibicionDropDown} />
				</button>
				<button className='column-box'>
					<IngresoPromocionDropDown
						head={activacion ?? 'Activación'}
						list={activaciones}
						id={"activacion_" + id}
						disabled={(view && true) || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						idCompuesto={"activacionField_" + id}
						setValueDropDown={setActivacionDropDown}
					/>
					<input type='hidden' id={"activacion_" + id} value={activacionDropDown} />
				</button>
				<button className='column-box'>
					<div className={`input-box ${valInvAdicional ? 'inputError' : ''}`}
						id={"invadicionalField_" + id}>
						<span></span>
						<input type='text' className='inputStyle'
							defaultValue={numeroaDinero(invAdicional)}
							id={"invadicional_" + id}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									e.stopPropagation();
									calculoPrecio(e)
									e.target.value = numeroaDinero(dineroaNumero(e.target.value));
									// return false;
								}
							}}
							onBlur={(e) => {
								e.target.value = numeroaDinero(dineroaNumero(e.target.value));
							}}
							onChange={(e) => {
								e.target.value = numeroaDinero(dineroaNumero(e.target.value));
								if (dineroaNumero(e.target.value) < 0
								) {
									setValInvAdicional(true);
								} else {
									setValInvAdicional(false);
								}
							}}
							disabled={(view && "disabled") || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						/>
					</div>
				</button>
				<p className='heading-column'>Producto</p>
				<button className='column-box'>
					<div className={`input-box ${valMGCliente ? 'inputError' : ''}`}
						id={"mgclienteField_" + id}>
						<input type='text' className='inputStyle'
							defaultValue={numeroaDescuento(mgCliente)}
							id={"mgcliente_" + id}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									e.stopPropagation();
									// alert(e.target.value);
									calculoPrecio(e)
									// alert(e.target.value);
									// e.target.value = parseFloat(e.target.value).toFixed(2);
									// return false;

								}
							}}
							onBlur={(e) => {
								calculoPrecio(e)
							}}
							onChange={(e) => {
								document.getElementById("mgclienteField_" + id).classList.remove("inputError");
								document.getElementById("pvpField_" + id).classList.remove("inputError");
								e.target.value = e.target.value.replace(/[^-\d.,]/g, '');
								if (descuentoaNumero(e.target.value) < -500 ||
									descuentoaNumero(e.target.value) >= 500
								) {
									setValMGCliente(true);
									setValPVP(true);
								} else {
									setValMGCliente(false);
									setValPVP(false);
								}
							}}
							disabled={(view && "disabled") || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						/>
						<span>%</span>
					</div>
				</button>
				<button className='column-box'>
					<div className={`input-box ${valPVP ? 'inputError' : ''}`}
						id={"pvpField_" + id}>
						<span></span>
						<input type='text' className='inputStyle'
							defaultValue={numeroaDinero(pvp)}
							id={"pvp_" + id}
							onChange={(e) => {
								calculoPrecio(e)
								document.getElementById("pvpField_" + id).classList.remove("inputError");
								if (dineroaNumero(e.target.value) <= 0
								) {
									setValPVP(true);
									setValMGCliente(true);
								} else {
									setValPVP(false);
									setValMGCliente(false);
								}
							}}
							disabled={(view && "disabled") || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						/>
					</div>
				</button>
				<p className='heading-column'>Producto</p>
				{(tipoPromocion && tipoPromocion === 'SELL-IN') &&
					<button className='column-box'>
						<IngresoPromocionDropDownDate head={inicioSellinDropDown && terminoSellinDropDown ?
							inicioSellinDropDown + " " + terminoSellinDropDown
							:
							'Sell-In'}
							id={id + "_sellin"}
							disabled={(view && true) || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
							idCompuesto={"sellinField_" + id}
							setValueDropDownInit={setInicioSellinDropDown}
							setValueDropDownEnd={setTerminoSellinDropDown}
							view={view}
							tipoPromocion={tipoPromocion}
						/>
						<input type='hidden' id={"inicio_" + id + "_sellin"} value={inicioSellinDropDown} />
						<input type='hidden' id={"termino_" + id + "_sellin"} value={terminoSellinDropDown} />
					</button>
				}
				<button className='column-box'>
					<IngresoPromocionDropDownDate head={inicioSelloutDropDown && terminoSelloutDropDown ?
						inicioSelloutDropDown + " " + terminoSelloutDropDown
						:
						'Sell-Out'}
						id={id + "_sellout"}
						disabled={(view && true) || (estadoPromocion !== "1" && estadoPromocion !== "3" && localStorage.getItem('rol') === 'KAM')}
						idCompuesto={"selloutField_" + id}
						setValueDropDownInit={setInicioSelloutDropDown}
						setValueDropDownEnd={setTerminoSelloutDropDown}
						view={view}
						tipoPromocion={tipoPromocion}
					/>
					<input type='hidden' id={"inicio_" + id + "_sellout"} value={inicioSelloutDropDown} />
					<input type='hidden' id={"termino_" + id + "_sellout"} value={terminoSelloutDropDown} />
				</button>
			</div>
		</div>
		</>
	)
}
