import {envVariables} from '../utils/envVariables/envVariables'
import PromoApi from '../utils/config/apiPromobooster'

const token = localStorage.getItem('token')

export const getValidationPromo = async (id, estado) => {
    
  // const sessionToken = localStorage.getItem('token')
  return PromoApi.get(`${envVariables.baseUrl}/promotion/validate/move/`+id+`/`+estado, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        return response
      }).catch((error) => {
        console.log(error)
        return {
          "errores": ["No es posible mover de fase el catálogo, por favor revisar en 'Editar'"]
          ,
          "errores_totales": 1
        }
      });
  }

export const movePhase = async (id, estadoPromocional) => {

    const sessionToken = localStorage.getItem('token')
    const response = await fetch(`${envVariables.baseUrl}/promotion/update/estado_promocional`, {
        // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            "Access-Control-Allow-Origin" : "*", 
            "Access-Control-Allow-Credentials" : true
          },
          method: "POST",
          body: JSON.stringify({
            "id": id,
            "estado_promocional": estadoPromocional
        })
        });
        const responseJSON = await response.json();
        // document.getElementById("btn_guardado_save").innerHTML = "Guardar Catálogo";
        console.log("Respuesta Guardado!!!! FINAL FINAL FINAL:::");
        console.log(responseJSON);
        return responseJSON;
    }
