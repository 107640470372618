/* eslint-disable dot-notation */
import lider from '../../assets/img/lider.svg'
import unimarc from '../../assets/img/unimarc.svg'
import mayorista10 from '../../assets/img/10Img.svg'
import tottus from '../../assets/img/tottus1.svg'
import jumbo from '../../assets/img/jumbonew.svg'
import acuenta from '../../assets/img/acuentalogos.svg'
import alvi from '../../assets/img/alvilogos.svg'
import alvicl from '../../assets/img/alvipuntocl.png'
import cm from '../../assets/img/cmlogos.svg'
import cugat from '../../assets/img/cugatlogos.svg'
import famaycorona from '../../assets/img/famaycoronalogos.svg'
import ganga from '../../assets/img/gangalogos.svg'
import jumbologo from '../../assets/img/jumbologos.svg'
import kuncar from '../../assets/img/kuncarlogos.svg'
import monserrat from '../../assets/img/montserratlogos.svg'
import okmarketlogos from '../../assets/img/okmarketlogos.svg'
import santaisabellogos from '../../assets/img/santaisabellogos.svg'
import trebollogos from '../../assets/img/trebollogos.svg'
import unimarclogos from '../../assets/img/unimarclogos.svg'
import versluyslogos from '../../assets/img/versluyslogos.svg'
import super10logos from '../../assets/img/super10logos.svg'
import centralmayoristalogos from '../../assets/img/centralmayoristalogos.svg'
import express from '../../assets/img/expresslogos.svg'
import provimarket from '../../assets/img/provimarketlogos.svg'
import super9 from '../../assets/img/super9logo.svg'
import ohiggins from '../../assets/img/ohigginslogos.svg'
import super8 from '../../assets/img/super8logos.svg'
import tottuspuntocl from '../../assets/img/tottuspuntocl.png'
import jumbopuntocl from '../../assets/img/jumbopuntocl.png'
import liderpuntocl from '../../assets/img/liderpuntocl.png'
import unimarcpuntocl from '../../assets/img/unimarcpuntocl.png'
import santaisabelpuntocl from '../../assets/img/santaisabelpuntocl.png'
import ekono from '../../assets/img/ekono.png'
import spid from '../../assets/img/Spid.png'

export const getGrocersImg = (grocersName) => {
	// console.log("CARGANDO IMAGEN DE::::::::::::  "+grocersName)
	const objImg = {
		SPID: spid,
		EKONO: ekono,
		ACUENTA: acuenta,
		ALVI: alvi,
		'ALVI.CL': alvicl,
		CM: cm,
		CUGAT: cugat,
		FAMAYCORONA: famaycorona,
		'SUPERGANGA': ganga,
		'GANGA': ganga,
		'JUMBO.CL': jumbopuntocl,
		JUMBO: jumbo,
		KUNCAR: kuncar,
		'LIDER.CL': liderpuntocl,
		LIDER: lider,
		MAYORISTA10: mayorista10,
		MONTSERRAT: monserrat,
		OKMARKET: okmarketlogos,
		OKM: okmarketlogos,
		SUPER10: super10logos,
		SANTAISABEL: santaisabellogos,
		'SANTAISABEL.CL': santaisabelpuntocl,
		'STA.ISABEL': santaisabellogos,
		// SPID: spid, // falta logo
		'TOTTUS.CL': tottuspuntocl,
		TOTTUS: tottus,
		// TELEMERCADO: telemeracados, // ESTE LOGO FALTA!!!!
		TREBOL: trebollogos,
		'UNIMARC.CL': unimarcpuntocl,
		UNIMARC: unimarclogos,
		VERSLUYS: versluyslogos,
		CENTRALMAYORISTA: centralmayoristalogos,
		EXPRESS: express,
		PROVIMARKET: provimarket,
		'EL9': super9,
		"O'HIGGINS": ohiggins,
		'SUPER8': super8,
	}
	
	console.log("CADENA A ELEGIR:::::: " + String(grocersName).toUpperCase())
	return objImg[String(grocersName).toUpperCase()] || String(grocersName).toUpperCase()
}
