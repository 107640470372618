import React, {useEffect, useState} from 'react'
import {IngresoCard} from './simulacion/IngresoCard'

import {BixialLineChart} from './simulacion/BixialLineChart'
import {ScatterChartComponent} from './simulacion/ScatterChartComponent'
import {StackedChartComponent} from './simulacion/StackedChartComponent'
import { numeroaDescuento, numeroaDinero } from './helper'

export const Simulacion = ({simulacionData, simulacionPage, setModalProduct, setDataProduct}) => {
    console.log("DATA FILAS SIMULACION!!!!!!!");
    console.log(simulacionData.resultado_simulacion);

	// Obtener el item de localStorage
	const [productos, setProductos] = useState([]);
  	const [resultados, setResultados] = useState([]);

	const actualizarProductos = () => {
		const gestionProductos = localStorage.getItem('gestionProductos');
		// alert(gestionProductos)
		if (gestionProductos) {
		  setProductos(JSON.parse(gestionProductos));
		} else {
		  setProductos([]);
		}
	};

	useEffect(() => {
		// Inicialmente obtener los productos
		// alert(simulacionPage)
		actualizarProductos();
	  }, [localStorage.getItem('gestionProductos')]);

	
	const productoExiste = (idProducto) => {
			return productos.filter(producto => producto.id_producto === idProducto)[0];
	}

	return (

			<>
				<div className='left-area'>
					<div className='top-area-ingreso' style={{width: "600px"}}>
						<p>{ /* ROI */ } ROI %</p>
						<div className='top'>
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle cx='8' cy='8' r='7.5' fill='#BDE9E8' stroke='#29BAB8' />
							</svg>
							mayor o igual a 5%
						</div>
						<div className='top'>
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle cx='8' cy='8' r='7.5' fill='#FEE6C1' stroke='#FFAC23' />
							</svg>
							entre 5% y -5%
						</div>
						<div className='top'>
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle cx='8' cy='8' r='7.5' fill='#FFCBC2' stroke='#FF5334' />
							</svg>
							menor o igual a -5%
						</div>
						<div className='top'>
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle cx='8' cy='8' r='7.5' fill='#FAFAFA' stroke='#C7C7C7' />
							</svg>
							{ /* Sin ROI */ } Sin Datos
						</div>
					</div>
					<div className='grid-wrapper-card ingreso'>
            {
                simulacionData.resultado_simulacion.map((item, index) => {
								console.log("ITEM DE SIMULACIÓN:::::::::: ")
								console.log(item)
								let fontColor = '#FFF6E8';
								let bgColor = '#FAFAFA';
								/*
								const mgComercialIncrPct = 
								(
									parseFloat(item.margen_comercial_incremental.replace(/\./g, "").replace(/,/g, "."))
									/
									(
									parseFloat(item.margen_comercial.replace(/\./g, "").replace(/,/g, "."))
									-
									parseFloat(item.margen_comercial_incremental.replace(/\./g, "").replace(/,/g, "."))
									)

								) * 100
									; */
								const roiNum = parseFloat(item.roi.replace(",","."));
								// alert(mgComercialIncrPct)
								if(roiNum >= 5){
									fontColor = '#29BAB8';
									bgColor = '#E1FAFA';
								}else if(roiNum >= -5 && roiNum < 5){
									fontColor = '#FFAC23';
									bgColor = '#FFF6E8';
								}else if(roiNum < -5){
									fontColor = '#FF5334';
									bgColor = '#FFE9E5';
								}else{
									fontColor = '#FFF6E8';
									bgColor = '#FAFAFA';
								}
								/*
								// ROI colores
								if(parseFloat(item.roi.replace(",",".")) >= 5){
									fontColor = '#29BAB8';
									bgColor = '#E1FAFA';
								}else if(parseFloat(item.roi.replace(",",".")) >= -5 && parseFloat(item.roi.replace(",",".")) < 5){
									fontColor = '#FFAC23';
									bgColor = '#FFF6E8';
								}else if(parseFloat(item.roi.replace(",",".")) < -5){
									fontColor = '#FF5334';
									bgColor = '#FFE9E5';
								}else{
									fontColor = '#FFF6E8';
									bgColor = '#FAFAFA';
								}
								*/
								// validacion para ver si esta app o recc
                                   return  <>
                                    <IngresoCard fontColor={fontColor} bgColor={bgColor} 
                                    key={index}
                                    sku={item.sku}
                                    descripcion={item.descripcion}
                                    roi={item.roi+"%"}
                                    precioLista={"$"+item.precio_lista}
                                    mgIncrementalPorcentaje={item.margen_incremental_pct+"%"}
                                    mgIncrementalDinero={"$"+item.margen_incremental.split(",")[0]}
                                    mecanica={item.mecanica}
                                    descuento={item.descuento}
                                    upliftPorcentual={item.uplift_porcentual+"%"}
									uplift={item.uplift}
                                    simulado={item.simulado}
                                    mensaje={item.mensaje}
									item={item}
									margenPromocion={"$"+item.margen_promocion}
									margenComercial={"$"+item.margen_comercial}
									margenComercialIncremental={"$"+item.margen_comercial_incremental}
									margenComercialIncrementalPorcentaje={item.margen_comercial_incremental_pct+"%"}
									estadoProducto={productoExiste(item.id_producto)}
									setModalProduct={setModalProduct}
									setDataProduct={setDataProduct}
                                    />
                                    </>
                            })
                        }
                        { /*
						<IngresoCard fontColor='#29BAB8' bgColor='#E1FAFA' /> OK ROI '5%
						<IngresoCard fontColor='#FFAC23' bgColor='#FFF6E8' /> Sin roi
						<IngresoCard fontColor='#29BAB8' bgColor='#E1FAFA' /> OK ROI + 5%
						<IngresoCard
							fontColor='#FF5334' Roi - 5%
							bgColor='#FFE9E5'
							error
							error3
							error2
						/>
						<IngresoCard fontColor='#29BAB8' bgColor='#E1FAFA' />
						<IngresoCard fontColor='#FF5334' bgColor='#FFE9E5' error error1 />
						<IngresoCard fontColor='#29BAB8' bgColor='#E1FAFA' />
						<IngresoCard
							fontColor='#FF5334'
							bgColor='#FFE9E5'
							error
							error3
							error2
						/>
						<IngresoCard fontColor='#FFAC23' bgColor='#FFF6E8' /> ENTRE -5 Y 5
                    */ }
					</div>
				</div>

				<div className='right-side'>
					<div className='top-area'>
						<h1>Simulación</h1>
						<span className='badge'>
							<svg
								width='16'
								height='16'
								viewBox='0 0 16 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g clipPath='url(#clip0_778_87586)'>
									<mask
										id='mask0_778_87586'
										maskUnits='userSpaceOnUse'
										x='0'
										y='0'
										width='16'
										height='16'
									>
										<rect width='16' height='16' fill='#D9D9D9' />
									</mask>
									<g mask='url(#mask0_778_87586)'>
										<path
											d='M9.64922 10.6666C9.78255 10.8 9.94655 10.8666 10.1412 10.8666C10.3354 10.8666 10.5048 10.7944 10.6492 10.65C10.7826 10.5166 10.8492 10.3471 10.8492 10.1413C10.8492 9.93595 10.7826 9.76662 10.6492 9.63328L8.73255 7.69995V5.39995C8.73255 5.18884 8.66322 5.01662 8.52455 4.88328C8.38544 4.74995 8.21033 4.68328 7.99922 4.68328C7.78811 4.68328 7.61322 4.75262 7.47455 4.89128C7.33544 5.0304 7.26589 5.20551 7.26589 5.41662V7.96662C7.26589 8.06662 7.28544 8.16373 7.32455 8.25795C7.36322 8.35262 7.42144 8.43884 7.49922 8.51662L9.64922 10.6666ZM7.99922 14.8C7.05477 14.8 6.16855 14.6222 5.34055 14.2666C4.513 13.9111 3.79366 13.4277 3.18255 12.8166C2.57144 12.2055 2.08811 11.4862 1.73255 10.6586C1.377 9.83062 1.19922 8.9444 1.19922 7.99995C1.19922 7.05551 1.377 6.16928 1.73255 5.34128C2.08811 4.51373 2.57144 3.7944 3.18255 3.18328C3.79366 2.57217 4.513 2.08884 5.34055 1.73328C6.16855 1.37773 7.05477 1.19995 7.99922 1.19995C8.94366 1.19995 9.82989 1.37773 10.6579 1.73328C11.4854 2.08884 12.2048 2.57217 12.8159 3.18328C13.427 3.7944 13.9103 4.51373 14.2659 5.34128C14.6214 6.16928 14.7992 7.05551 14.7992 7.99995C14.7992 8.9444 14.6214 9.83062 14.2659 10.6586C13.9103 11.4862 13.427 12.2055 12.8159 12.8166C12.2048 13.4277 11.4854 13.9111 10.6579 14.2666C9.82989 14.6222 8.94366 14.8 7.99922 14.8ZM7.99922 13.2833C9.46589 13.2833 10.7132 12.7695 11.7412 11.742C12.7688 10.714 13.2826 9.46662 13.2826 7.99995C13.2826 6.53328 12.7688 5.28595 11.7412 4.25795C10.7132 3.2304 9.46589 2.71662 7.99922 2.71662C6.53255 2.71662 5.28544 3.2304 4.25789 4.25795C3.22989 5.28595 2.71589 6.53328 2.71589 7.99995C2.71589 9.46662 3.22989 10.714 4.25789 11.742C5.28544 12.7695 6.53255 13.2833 7.99922 13.2833Z'
											fill='#949494'
										/>
									</g>
								</g>
								<defs>
									<clipPath id='clip0_778_87586'>
										<rect width='16' height='16' fill='white' />
									</clipPath>
								</defs>
							</svg>

							<p>17:45 | 30/12/22</p>
						</span>
					</div>

					<div className='bottom-chart-area'>
						<div>
							<BixialLineChart />
							{/* <img src={secondChart} alt='' /> */}
						</div>
						<div>
							<ScatterChartComponent />
							{/* <img src={firstChart} alt='' /> */}
						</div>
						<div>
							<StackedChartComponent />
							{/* <img src={thirdChart} alt='' /> */}
						</div>
					</div>
				</div>
            </>
	)
}
