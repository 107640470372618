import {Splide, SplideSlide} from '@splidejs/react-splide'
// Default theme
import '@splidejs/react-splide/css'

// or other themes
import '@splidejs/react-splide/css/skyblue'
import '@splidejs/react-splide/css/sea-green'

// or only core styles
import '@splidejs/react-splide/css/core'
import iconnew from '../../assets/img/iconnew.svg'
import iconnewgray from '../../assets/img/iconnewgray.svg'
import styles from './filterdata.module.scss'

/* eslint-disable dot-notation */
import lider from '../../assets/img/lider.svg'
import unimarc from '../../assets/img/unimarc.svg'
import mayorista10 from '../../assets/img/10Img.svg'
import tottus from '../../assets/img/tottus1.svg'
import jumbo from '../../assets/img/jumbonew.svg'
import acuenta from '../../assets/img/acuentalogos.svg'
import alvi from '../../assets/img/alvilogos.svg'
import cm from '../../assets/img/cmlogos.svg'
import cugat from '../../assets/img/cugatlogos.svg'
import famaycorona from '../../assets/img/famaycoronalogos.svg'
import ganga from '../../assets/img/gangalogos.svg'
import jumbologo from '../../assets/img/jumbologos.svg'
import kuncar from '../../assets/img/kuncarlogos.svg'
import monserrat from '../../assets/img/montserratlogos.svg'
import okmarketlogos from '../../assets/img/okmarketlogos.svg'
import santaisabellogos from '../../assets/img/santaisabellogos.svg'
import trebollogos from '../../assets/img/trebollogos.svg'
import unimarclogos from '../../assets/img/unimarclogos.svg'
import versluyslogos from '../../assets/img/versluyslogos.svg'
import super10logos from '../../assets/img/super10logos.svg'
import centralmayoristalogos from '../../assets/img/centralmayoristalogos.svg'
import express from '../../assets/img/expresslogos.svg'
import provimarket from '../../assets/img/provimarketlogos.svg'
import super9 from '../../assets/img/super9logo.svg'
import ohiggins from '../../assets/img/ohigginslogos.svg'
import super8 from '../../assets/img/super8logos.svg'
import tottuspuntocl from '../../assets/img/tottuspuntocl.png'
import jumbopuntocl from '../../assets/img/jumbopuntocl.png'
import liderpuntocl from '../../assets/img/liderpuntocl.png'
import unimarcpuntocl from '../../assets/img/unimarcpuntocl.png'
import santaisabelpuntocl from '../../assets/img/santaisabelpuntocl.png'

export const FilterDataBox = ({
	heading,
	list,
	icon,
	part,
	setActivatePopupBoxState = null,
	catalog,
	estado
}) => {

	const getGrocersImg = (grocersName) => {
		// console.log("CARGANDO IMAGEN DE::::::::::::  "+grocersName)
		const objImg = {
			ACUENTA: acuenta,
			ALVI: alvi,
			'Alvi.cl': alvi,
			CM: cm,
			CUGAT: cugat,
			FAMAYCORONA: famaycorona,
			'FAMA Y CORONA': famaycorona,
			'SUPERGANGA': ganga,
			'GANGA': ganga,
			'JUMBO.CL': jumbopuntocl,
			JUMBO: jumbo,
			KUNCAR: kuncar,
			'LIDER.CL': liderpuntocl,
			LIDER: lider,
			MAYORISTA10: mayorista10,
			MONTSERRAT: monserrat,
			OKMARKET: okmarketlogos,
			OKM: okmarketlogos,
			SUPER10: super10logos,
			SANTAISABEL: santaisabellogos,
			'SANTAISABEL.CL': santaisabelpuntocl,
			'STA. ISABEL': santaisabellogos,
			// SPID: spid, // falta logo
			'TOTTUS.CL': tottuspuntocl,
			TOTTUS: tottus,
			// TELEMERCADO: telemeracados, // ESTE LOGO FALTA!!!!
			TREBOL: trebollogos,
			'UNIMARC.CL': unimarcpuntocl,
			UNIMARC: unimarclogos,
			VERSLUYS: versluyslogos,
			CENTRALMAYORISTA: centralmayoristalogos,
			EXPRESS: express,
			PROVIMARKET: provimarket,
			'EL9': super9,
			"O'HIGGINS": ohiggins,
			'SUPER8': super8,
		}
	
		return objImg[String(grocersName).toUpperCase()] || String(grocersName).toUpperCase()
	}
	

	return (
		<div
			className={`FilterDataBox ${part && 'gray'}`}
			onClick={(e) => {
				if (setActivatePopupBoxState !== null) {
					setActivatePopupBoxState(true)
				}
			}}
		> 
			{(estado === 'edicion' && icon === 1) && (
				<div className='FilterDataBoxIconWrapper'>
					
					<img
						src={iconnew}
						alt='FilterDataBoxIcon'
						className='FilterDataBoxIcon'
					/>
					<p>Con Cambios</p>
				</div>
			)}

			{(estado === 'ejecucion' && icon === 1) && (
				<div className='FilterDataBoxIconWrapper'>
					
					<img
						src={iconnewgray}
						alt='FilterDataBoxIcon'
						className='FilterDataBoxIcon'
					/>
					<p>Por Ejecutar</p>
				</div>
			)}
			
			<p>{catalog?.fecha_ingreso || 'Sin fecha'}			
			</p>
			
			<h1>{heading}</h1>
			<div className='tags_wrapper'>
				<Splide
					aria-label='My Favorite Images'
					className='splideMain'
					options={{
						pagination: false,
						arrows: false,
						autoWidth: true,
						// perPage: 3,
						gap: 5,
					}}
				>
					{list.map((EachList) => (
						<SplideSlide key={EachList.name}>
							{EachList.name ? (
								<div className='tag'>
									{/* <div className='tag' style={{borderColor: `${EachList.color}`}}> */}
									<p>
										{EachList.name} 
										<span>{EachList.percentage}</span>
									</p>
								</div>
							) : (
								<div className='tag'>
									<div style={{maxWidth: '100%'}}>
										<img
											// src={EachList.img}
											src = {getGrocersImg(String(EachList.img_txt))}
											alt=''
											style={{
												width: '60px',
												height: '100%',
												objectFit: 'cover',
												padding: '10px 0',
											}}
										/>
									</div>
								</div>
							)}
						</SplideSlide>
					))}
				</Splide>
			</div>
		</div>
	)
}
