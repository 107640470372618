import {useReducer} from 'react'
import {CatalogsContext} from './CatalogsContext'
import {catalogReducer} from './catalogReducer'
import {types} from './types'
import {getAllCatalogs} from '../../services/catalog.service'

const initialState = {
	catalogs: {},
	loading: false,
	errorMessage: null,
}

export const CatalogsProvider = ({children}) => {
	const [catalogsState, dispatch] = useReducer(catalogReducer, initialState)

	const allCatalogs = async () => {
		try {
			dispatch({
				type: types.request,
			})
			const response = await getAllCatalogs()

			if (response.status === 200) {
				if(response.data.en_edicion.length > 0){
					response.data.en_edicion = 
					response.data.en_edicion.sort((a,b) => b.id_promo - a.id_promo)
				}
				if(response.data.en_ejecucion.length > 0){
					response.data.en_ejecucion = 
					response.data.en_ejecucion.sort((a,b) => b.id_promo - a.id_promo)
				} 
				if(response.data.negociacion.length > 0){
					response.data.negociacion = 
					response.data.negociacion.sort((a,b) => b.id_promo - a.id_promo)
				} 
				if(response.data.revision.length > 0){
					response.data.revision = 
					response.data.revision.sort((a,b) => b.id_promo - a.id_promo)
				} 
				dispatch({
					type: types.catalogs,
					payload: response.data,
				})
			} else {
				dispatch({
					type: types.error,
					payload: response.data.response,
				})
			}
		} catch (error) {
			dispatch({
				type: types.error,
				payload: error?.toString(),
			})
		}
	}

	return (
		<CatalogsContext.Provider value={{catalogsState, allCatalogs}}>
			{children}
		</CatalogsContext.Provider>
	)
}
